// Homne.js

import React from 'react';
import HomePicture from '../../img/HomePictureSmall.jpg';
import '../../Style.css';
import Form from '../../Form'

const Home = () => {
  return (
    <div className="main-container">
    <img className="responsive-img" src={HomePicture} alt="CompanyRepresentation"></img>
    <div className="container mt-4 split-container">
      
      <div className="text-section mt-3">
        <p>
          4CY is a Canadian market research firm providing fast, reliable, and affordable services related to marketing research, public opinion research, public consultations, and data science.
        </p>
       
        <p>
          Together with our select network of partners and associates we can accommodate to tight timelines and budgets without having to sacrifice quality. 
          Take a look at how we can help!
        </p>
      </div>
      <div className="form-section">
        <Form />
      </div>
    </div>
  </div>
);
};

export default Home;
