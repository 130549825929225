// About.js

import React from 'react';
import AboutUs from '../../img/AboutUs.jpg';
import '../../Style.css';

const About = () => {
  return (
    <div className="main-container">
    <div className="d-flex flex-column">
  
    {/* <div className="container flex-grow-1"> */}
    <img className="responsive-img" src={AboutUs} alt="AboutUs"></img>
    {/* <h1 className="text-center mb-4">About Us</h1> */}
    <div className="container flex-grow-1">
      <div className="mt-3">
      <p >4CY is a Canadian market research firm providing fast, reliable, and affordable services related to marketing research, public opinion research, public consultations, and data science.</p>
      <p>With so many sources and tools out there, we understand that market research is quickly becoming a commodity. Therefore, we keep it simple and affordable, leaving all the jazz and hype to others.</p>
      <p>Our <a href="/services">services</a> include, among others, expert advice on research design, in-person fieldwork, data coding, statistical analysis, charting, and reporting. 4CY will also be publishing syndicated reports based on primary and secondary research, covering a variety of topics.</p>


      </div>
    
      </div>
    </div>
   
  </div>
  // </div>
  );
};

export default About;
