// FullserviceResearch.js

import React from 'react';
import { Link } from 'react-router-dom';
import chart from '../../img/Charting.png';
import offTheShelf from '../../img/OffTheShelf.png';
import researchConsulting from '../../img/ResearchConsulting.png';


const FullserviceResearch = () => {
  return (
    <div className="container mt-4">
    <div className="d-flex flex-column ">
  
    <div className="container flex-grow-1">
    <h2 className="text-center mb-4">FULL-SERVICE RESEARCH</h2>
    <p>4CY has a select network of partners within Canada, the US, Mexico, and Central America for your full-service research needs at affordable prices.</p>
        <p>We are well versed in both qualitative and quantitative methodologies for marketing research, public opinion research, and public consultations.</p>
        <p>Below are some examples of the types of research we are able to conduct throughout North and Central America:</p>
        <ul>
          <li>
            <strong>Qualitative</strong>
            <ul>
              <li>Ethnographies</li>
              <li>Shop-along studies</li>
              <li>Focus Groups</li>
              <li>IDIs</li>
              <li>Action Research</li>
              <li>Content Analysis</li>
            </ul>
          </li>
          <li>
            <strong>Quantitative</strong>
            <ul>
              <li>U&A</li>
              <li>Segmentations</li>
              <li>Product test (taste, fragrance, HUT)</li>
              <li>Pricing research</li>
              <li>Conjoint analysis</li>
              <li>Brand Equity (Awareness, Perception, Consideration, Purchase Intent)</li>
              <li>Advertising awareness and testing</li>
            </ul>
          </li>
          <li>
            <strong>Public Opinion and Public Consultations</strong>
            <ul>
              <li>Online communities</li>
              <li>Online and in-person public consultation and data collection</li>
              <li>Public opinion surveys</li>
            </ul>
          </li>
        </ul>
        <p>Reach out for a quote!</p>

    </div>
   
{/* Navigation cards */}
<div className="row mt-4">
                <div className="col-md-4 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                    <img src={researchConsulting} alt="Research Consulting" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/researchConsulting" className="card-title h5">Research Consulting</Link>
               
              </div>
                    </div>
                  </div>
                </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={offTheShelf} alt="Off-the-Shelf Reports" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/offtheShelfReports" className="card-title h5">Off-the-Shelf Reports</Link>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={chart} alt="Charting and Data Analysis" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/chartingAndDataAnalysis" className="card-title h5 ml-2">Charting and Data Analysis</Link>
              </div>
              </div>
            </div>
          </div>
        </div>


  </div>
  </div>
  );
};

export default FullserviceResearch;