import React from "react";

export default function Form() {
  return (
   
    <form name="contact" method="post" className="mt-3">
      <input type="hidden" name="form-name" value="contact" />
      <div className = "mb-3">
        <label htmlFor="name" className="form-label">Name*</label> <br />
        <input type="text" className="form-control" id="name" name="name" required />
      </div>

      <div className = "mb-3">
        <label htmlFor="company" className="form-label">Company</label> <br />
        <input type="company" className="form-control" id="company" name="company"/>
      </div>

      <div className = "mb-3">
        <label htmlFor="email" className="form-label">Email*</label> <br />
        <input type="email" className="form-control" id="email" name="email" required />
      </div>
      <div className = "mb-3">
        <label htmlFor="message" className="form-label">Message*</label> <br />
        <textarea id="message" className="form-control" name="message" required></textarea>
      </div>
      <p className="text-center">
        <input type="submit" value="Submit message" className="btn btn-info" />
      </p>
    </form>
  );
}