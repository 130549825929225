// ChartingAndDataAnalysis.js

import React from 'react';
import { Link } from 'react-router-dom';
import fullService from '../../img/FullService.png';
import offTheShelf from '../../img/OffTheShelf.png';
import researchConsulting from '../../img/ResearchConsulting.png';

const ChartingAndDataAnalysis = () => {
  return (
    <div className="container mt-4">
    <div className="d-flex flex-column ">
  
    <div className="container flex-grow-1">
    <h1 className="text-center mb-4 " >CHARTING AND DATA ANALYSIS</h1>
    <p>We get it… you are busy! Let us take care of those tedious but important time-consuming tasks so you can focus more on the big picture.</p>
    <p>Our Business Process Outsourcing (BPO) model allows us to tap into local and international resources to accommodate to tight timelines and budgets.</p>
    <p>Below are some examples of how we can help:</p>
    <ul>
      <li>Data mining</li>
      <li>Data cleaning and formatting</li>
      <li>Data validation</li>
      <li>Coding of verbatim responses</li>
      <li>Creation of data tables</li>
      <li>Image capture and analysis through OCR</li>
      <li>Statistical analyses including factor analysis, cluster analysis, conjoint analysis, multiple regressions, and discriminant analysis</li>
      <li>Data visualization and dashboard creation using Tableau and Power BI</li>
      <li>Charting and report writing using MSOffice and Google Suite tools</li>
      <li>Infographics</li>
      <li>Report review</li>
    </ul>
    <p>Let us know what you need. We’ll make it happen!</p>
    </div>

 {/* Navigation cards */}
 <div className="row mt-4">
                <div className="col-md-4 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                    <img src={fullService} alt="Full-service Research" className="mr-4" width="100" height="100" />
                      <div className="card-title m-4">
                          <Link to="/service-details/fullserviceResearch" className="card-title h5">Full-service Research</Link>
                          </div>
                    </div>
                  </div>
                </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={researchConsulting} alt="Research Consulting" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/researchConsulting" className="card-title h5">Research Consulting</Link>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={offTheShelf} alt="Off-the-Shelf Reports" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/offtheShelfReports" className="card-title h5">Off-the-Shelf Reports</Link>
              </div>
              </div>
            </div>
          </div>
        </div>


   
  </div>
  </div>
  );
};

export default ChartingAndDataAnalysis;