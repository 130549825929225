// ResearchConsulting.js

import React from 'react';
import { Link } from 'react-router-dom';
import chart from '../../img/Charting.png';
import fullService from '../../img/FullService.png';
import offTheShelf from '../../img/OffTheShelf.png';

const ResearchConsulting = () => {
  return (
    <div className="container mt-4">
    <div className="d-flex flex-column">
  
    <div className="container flex-grow-1">
    <h1 className="text-center mb-4">RESEARCH CONSULTING</h1>
          <p>Sometimes we just need to bounce ideas off other peers.</p>
      <p>If you have a good handle on your project but still feel the need for an extra pair of eyes, we can help!</p>
      <p>Our research consulting team can give you a hand with:</p>
      <ul>
        <li>Overall research design review</li>
        <li>Questionnaire / guide / instrument review</li>
        <li>Programming review (e.g. link testing, data output setup)</li>
        <li>Report review and data validation</li>
        <li>Source and fact checking</li>
      </ul>
      <p>Let us know how we can help!</p>

    </div>
   
 {/* Navigation cards */}
 <div className="row mt-4">
                <div className="col-md-4 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                    <img src={fullService} alt="Full-service Research" className="mr-4" width="100" height="100" />
                      <div className="card-title m-4">
                          <Link to="/service-details/fullserviceResearch" className="card-title h5">Full-service Research</Link>
                          </div>
                    </div>
                  </div>
                </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={offTheShelf} alt="Off-the-Shelf Reports" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/offtheShelfReports" className="card-title h5">Off-the-Shelf Reports</Link>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={chart} alt="Charting and Data Analysis" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/chartingAndDataAnalysis" className="card-title h5 ml-2">Charting and Data Analysis</Link>
              </div>
              </div>
            </div>
          </div>
        </div>



  </div>
  </div>
  );
};

export default ResearchConsulting;