import React from 'react';
import { Link } from 'react-router-dom';
import chart from '../../img/Charting.png';
import fullService from '../../img/FullService.png';
import offTheShelf from '../../img/OffTheShelf.png';
import researchConsulting from '../../img/ResearchConsulting.png';

const Services = () => {
  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Service List</h2>
      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body d-flex align-items-center">
              <img src={chart} alt="Charting and Data Analysis" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/chartingAndDataAnalysis" className="card-title h5 ml-2">Charting and Data Analysis</Link>
                <p className="card-text">Short on time and resources? Learn how our BPO model can help during crunch time!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body d-flex align-items-center">
              <img src={offTheShelf} alt="Off-the-Shelf Reports" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/offtheShelfReports" className="card-title h5">Off-the-Shelf Reports</Link>
                <p className="card-text">Coming soon! Check out some of the topics we’ll be covering or let us know what you are looking for.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body d-flex align-items-center">
              <img src={researchConsulting} alt="Research Consulting" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/researchConsulting" className="card-title h5">Research Consulting</Link>
                <p className="card-text">Just looking for advice or a “sanity check”? Learn how we can help you succeed in your next research project.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body d-flex align-items-center">
              <img src={fullService} alt="Full-service Research" className="mr-4" width="100" height="100" />
                <div className="card-title m-4">
                    <Link to="/service-details/fullserviceResearch" className="card-title h5">Full-service Research</Link>
                    <p className="card-text">Looking for a research partner that can take on projects from end to end? The sky is the limit.</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
