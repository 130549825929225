// Navigation.js

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/4CYLogo100x62.png';
import '../../Style.css';

const Navigation = () => {
  return (
    <nav className="navbar navbar-expand-lg  navbar-custom">
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-custom"> */}
    <div className="container">
        <img src = {logo} alt = "Company logo"></img>
      <ul className="navbar-nav mx-auto "> 
        <li className="nav-item">
          <Link className="nav-link" to="/home" >Home</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services">Services</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact</Link> 
        </li>
      </ul>
    </div>
  </nav>
  );
};

export default Navigation;
