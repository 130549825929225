// Contact.js

import React from 'react';
import Form from '../../Form'
import ContactUs from '../../img/ContactUs.jpg';
import '../../Style.css';

const Contact = () => {
  return (
    <div className="main-container">
    <div className="d-flex flex-column">
    <img className="responsive-img" src={ContactUs} alt="ContactUs"></img>
    <div className="container flex-grow-1">
    {/* <h1 className="text-center">Contact Us</h1> */}
     <h5 className="text-center mt-3">Reach out to let us know how we can help! </h5>
   
    <Form/>
    </div>
  </div>
  </div>
  );
};

export default Contact;
