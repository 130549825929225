// src/components/Footer.js
import React from 'react';
import '../../Style.css';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 navbar-custom">
          {/* <footer className="footer mt-auto py-3 bg-light navbar-custom"> */}
          <div className="container text-center">
            <span className="text-muted">4CY Research & Analytics. All rights reserved. © {new Date().getFullYear()}  </span>
          </div>
        </footer>
      );
    };

export default Footer;
