import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/NavBar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Services from './Components/Services/Services';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import FullServiceAndDataAnalysis from './Components/Services/FullServiceResearch';
import OffTheShelfResearch from './Components/Services/Off-the-ShelfReports';
import ChartingAndDataAnalysis from './Components/Services/ChartingAndDataAnalysis';
import ResearchConsulting from './Components/Services/ResearchConsulting';

const App = () => {
  return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Router>
      <Navbar /> 
      <Routes>
      {/* URLパスと特定のコンポーネントとのマッピングを定義 */}
      <Route path='/' element={<Home/>} />  
        <Route path='/home' element={<Home/>} />   
        <Route path='/services' element={<Services/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
       
        <Route path='/service-details/chartingAndDataAnalysis' element={<ChartingAndDataAnalysis/>} />
        <Route path='/service-details/offtheShelfReports' element={<OffTheShelfResearch/>} />
        <Route path='/service-details/researchConsulting' element={<ResearchConsulting/>} />
        <Route path='/service-details/fullserviceResearch' element={<FullServiceAndDataAnalysis/>} />
        
      </Routes>
    </Router>
   
    <Footer />
    </div>
  );
};

export default App;
