// OffTheShelfReports.js

import React from 'react';
import { Link } from 'react-router-dom';
import chart from '../../img/Charting.png';
import fullService from '../../img/FullService.png';
import researchConsulting from '../../img/ResearchConsulting.png';


const OffTheShelfReports = () => {
  return (
    <div className="container mt-4">
    <div className="d-flex flex-column vh-100">
  
    <div className="container flex-grow-1">
    <h1 className="text-center mb-4">OFF-THE-SHELF REPORTS</h1>
    <p><strong>Coming Soon!</strong></p>
        <p>Our team is currently crunching numbers and drafting our first off-the-shelf reports that will combine primary and secondary sources to provide insights on specific topics of interest.</p>
        <p>Here is a peek of what we are aiming (sell sheets will be available soon):</p>
        <ul>
          <li><strong>Deep Dive on Canadian Millennials and Xennials</strong>
            <ul>
              <li>A comprehensive focus on Millennials and Xennials from different regions with a broad comparison to other generations.</li>
              <li>Topics will include spending habits, media consumption, purchase behaviour and attitudes, preferences, perceptions, unmet needs, among others.</li>
              <li>The report will cover various categories (CPG, Health & Wellness, Finance, Entertainment, e-Commerce, Charitable behaviour, and others).</li>
            </ul>
            <p> Send us a note expressing your interest and you will be eligible for an early bird discount.</p>
          </li>
          <li><strong>Deep Dive on Asian Canadians and Newcomers</strong>
            <ul>
              <li>Content will follow the same outline as the deep dive on Millennials and Xennials.</li>
              <li>Focus will be on Chinese, Indian, and other Asian people living in Canada.</li>
            </ul>
            <p>Send us a note expressing your interest and you will be eligible for an early bird discount.</p>
          </li>
        </ul>
        <p>Costs will range between CAD$2,000 and CAD$10,000 depending on the deliverable needed (e.g. infographic only, executive summary only, full report).</p>
        <p>Come back soon for sell sheets with more details and for an update on upcoming 2020 topics.</p>
        <p>Let us know what you are looking for and we’ll try to include it in our pipeline.</p>

    </div>


 {/* Navigation cards */}
 <div className="row mt-4">
                <div className="col-md-4 mb-4">
                  <div className="card text-center">
                    <div className="card-body">
                    <img src={fullService} alt="Full-service Research" className="mr-4" width="100" height="100" />
                      <div className="card-title m-4">
                          <Link to="/service-details/fullserviceResearch" className="card-title h5">Full-service Research</Link>
                          </div>
                    </div>
                  </div>
                </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={researchConsulting} alt="Research Consulting" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/researchConsulting" className="card-title h5">Research Consulting</Link>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card text-center">
              <div className="card-body">
              <img src={chart} alt="Charting and Data Analysis" className="mr-4" width="100" height="100" />
              <div className="card-title m-4">
                <Link to="/service-details/chartingAndDataAnalysis" className="card-title h5 ml-2">Charting and Data Analysis</Link>
              </div>
              </div>
            </div>
          </div>
        </div>

   
  </div>
  </div>
  );
};

export default OffTheShelfReports;